<template>
  <div class="mv">
    <img src="/assets/img/img_top_kv.gif" alt="野菜と、未来と、わたしを耕すリモファム" width="1124" height="2436" decoding="async">
  </div>

  <section id="feature" class="tp-feature js-effect">
    <h2 class="tp-feature__title">
      <span class="title" lang="en">Feature</span>
      <span class="text">REMOFAMの3つの特徴</span>
    </h2>

    <section class="tp-feature__item --feature01 js-effect">
      <h3 class="tp-feature__subtitle font_e">
        <span class="title" lang="en">Feature</span>
        <span class="num">01</span>
      </h3>
      <div class="tp-feature__img"><img src="/assets/img/ph_top_feature01.jpg" alt="Feature01" width="700" height="592" decoding="async"></div>
      <p class="tp-feature__text">栽培する野菜や収穫量を<br>カスタマイズできる</p>
    </section>

    <section class="tp-feature__item --feature02 js-effect">
      <h3 class="tp-feature__subtitle font_e">
        <span class="title" lang="en">Feature</span>
        <span class="num">02</span>
      </h3>
      <div class="tp-feature__img"><img src="/assets/img/ph_top_feature02.jpg" alt="Feature02" width="700" height="592" decoding="async"></div>
      <p class="tp-feature__text">農薬、肥料不使用の農法や、<br>魅力ある地方で農園が持てる</p>
    </section>

    <section class="tp-feature__item --feature03 js-effect">
      <h3 class="tp-feature__subtitle font_e">
        <span class="title" lang="en">Feature</span>
        <span class="num">03</span>
      </h3>
      <div class="tp-feature__img"><img src="/assets/img/ph_top_feature03.jpg" alt="Feature03" width="700" height="592" decoding="async"></div>
      <p class="tp-feature__text">栽培は現地の農家さんにお任せ！<br>種子、苗、農作業費用<br>全て込みのプランで<br>誰でも始めやすい！</p>
    </section>
  </section>

  <section id="plan" class="tp-plan js-effect">
    <div class="tp-plan__inner">
      <h2 class="tp-plan__title js-effect">
        <span class="title" lang="en">Plan</span>
        <span class="text"><span>REMOFAMの</span><span>選べる2つのプラン</span></span>
      </h2>
      <p class="tp-plan__btn js-effect"><a href="plan" class="btn">契約農園のプランを見る</a></p>
    </div>
  </section>

  <section id="instafarm" class="instafarm js-effect">
    <h2 class="instafarm__title">
      <span class="title" lang="en">Instafarm</span>
      <span class="text">REMOFAM公式インスタグラム</span>
    </h2>
    <div class="instafarm__body">
      <div id="curator-feed-default-feed-layout"><a href=" https://curator.io " target="_blank" class="crt-logo crt-tag">Powered by Curator.io</a></div>
    </div>
  </section>

  <section id="contact" class="contact">
    <h2 class="contact__title js-effect">
      <span class="title" lang="en">Contact</span>
      <span class="text">お問い合わせ</span>
    </h2>
    <p class="contact__text js-effect">以下のメールアドレス宛に<br>ご連絡ください</p>
    <p class="contact__email js-effect"><a href="mailto:info@minnademiraio.net">info@minnademiraio.net</a></p>
  </section>

  <section id="information" class="information">
    <h2 class="information__title js-effect">
      <span class="title" lang="en">Information</span>
      <span class="text">運営会社</span>
    </h2>
    <div class="information__data js-effect">
      <p>フロムファーイースト株式会社<br>大阪市中央区備後町 2-4-6 8階</p>
    </div>
  </section>
</template>

<script>
  var i,e,d=document,s="script";i=d.createElement("script");i.defer=1;i.charset="UTF-8";
  i.src="https://cdn.curator.io/published/099d3293-f070-4095-bc7c-9a592b701616.js ";
  e=d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);
</script>
