<template>
  <div class="container">
    <div class="plan_mv _small">
      <img
        src="/assets/img/mv_plan_detail.jpg"
        alt=""
        width="748"
        height="354"
        decoding="async"
      />
    </div>
    <h1 class="ttl_basic02 u-ta_c">カスタマイズプラン<br />【STEP2】</h1>

    <h2 class="ttl_basic03">注意事項</h2>
    <ul>
      <li>
        ・収穫物は収穫後すぐに着払いにて発送します。送料は収獲量によって異なります。
      </li>
      <li>・収穫量を保証したものではございません。</li>
      <li>
        ・収穫物は生鮮品であるため、送付した収穫物を理由に関わらずお受け取りいただけなかった場合、お客様のその収穫物（複数回にわたり収穫物を送付する場合は、その時に送付した収穫物）の権利は失われ、再送や代わりの作物への代替等はいたしかねますので予めご了承ください。
      </li>
    </ul>

    <!-- 地域と土地の広さを決める -->
    <section id="customized_plan">
      <Form
        @change="activeForm = true"
        ref="formPlan"
        v-if="planData.breadth"
        v-slot="{ errors, values }"
        @submit="onSubmit"
        :validation-schema="schema"
        :initial-values="initialData"
      >
        <h2 class="ttl_basic02 u-mb0">栽培する野菜を選ぶ</h2>
        <div class="video u-mt_xxs">
          <img src="/assets/img/昨付けスケジュール.png" alt="" />
          <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/VisgWStgHmc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen loading="lazy"></iframe> -->
        </div>

        <div v-if="!isObjectEmpty(errors)" class="alert">
          <p class="alert__msg"><strong>未割り当ての畝があります。</strong></p>
        </div>

        <div id="card-agri-plan" class="u-mt_sm">
          <FieldArray name="planData" v-slot="{ fields }">
            <template v-for="(field, idx) in fields" :key="field.key">
              <div
                class="agri_plan"
                :class="{ disabled: enabledPlanIdx[field.key] }"
              >
                <h3 class="agri_plan__ttl">栽培計画{{ idx + 1 }}</h3>
                <label class="select_wrap" aria-label="割り当てる広さ">
                  <Field
                    :name="`planData[${idx}].furrow`"
                    as="select"
                    @change="
                      handleFurrowChange(fields, field, idx),
                        handlePlanIdx(values.planData, idx)
                    "
                  >
                    <option :value="0">割り当てる広さを選択</option>
                    <option
                      v-for="item in getFurrowsOptions(
                        values.planData,
                        field.value,
                        idx
                      )"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </option>
                  </Field>
                </label>

                <FieldArray
                  :name="`planData[${idx}].vegetables`"
                  v-slot="{ fields, push, remove }"
                >
                  <template v-for="(_field, _idx) in fields" :key="_field.key">
                    <label
                      class="select_wrap"
                      :class="{ selected: _field.value.id }"
                      aria-label="収穫したい野菜"
                    >
                      <div
                        @click="handleRemoveData(remove, field, _field, _idx)"
                        class="close-icon"
                      >
                        <svg
                          width="30px"
                          height="30px"
                          viewBox="0 0 64 64"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          stroke="#000000"
                        >
                          <line x1="16" y1="16" x2="48" y2="48" />
                          <line x1="48" y1="16" x2="16" y2="48" />
                        </svg>
                      </div>
                      <Field
                        :class="{
                          'is-invalid': handleStatusVegetable(field, _field),
                        }"
                        @change="
                          handlePlanIdx(values.planData, idx),
                            handleVegetableChange(
                              idx,
                              _idx,
                              push,
                              field,
                              _field.value,
                              values.planData[idx].vegetables,
                              remove,
                              values.planData
                            )
                        "
                        :name="`planData[${idx}].vegetables[${_idx}].id`"
                        as="select"
                      >
                        <option value="">
                          収穫したい野菜を選択（{{ _idx + 1 }}つ目）
                        </option>
                        <option
                          v-for="vegetable in getVegetableData(
                            values.planData,
                            _field.value,
                            values.planData[idx].vegetables,
                            idx
                          )"
                          :value="vegetable.id"
                        >
                          {{ vegetable.name }} {{ getDateRange(vegetable) }}
                        </option>
                      </Field>
                    </label>
                  </template>
                </FieldArray>
              </div>
            </template>
          </FieldArray>
        </div>

        <!-- 栽培計画の確認（未選択状態） -->
        <div class="agri_confirm u-mt_md">
          <div class="agri_confirm__item">
            <h3 class="ttl_sub01">年間の予測収穫量</h3>
            <p v-if="getVegetablesSelected(values).length === 0">
              生産する作物が選択されていません。
            </p>
            <ul v-else class="crop_list">
              <li
                v-for="(item, index) in getVegetablesSelected(values)"
                :key="index"
              >
                <span
                  :class="`vegeicon ${convertKatakanaToHiragana(
                    getVegetablesById(item.id)
                  )}`"
                ></span
                >{{ getVegetablesById(item.id) }}: {{ calculate(item) }}kg
              </li>
            </ul>
          </div>
        </div>

        <p class="u-mt_md">
          ・例年の収穫実績を元に算出をしています。上記の収穫量を保証するものではございません。
        </p>

        <input value="3" name="step" hidden />
        <!-- 申込みボタン -->
        <p class="btn-center">
          <button
            ref="btnSubmit"
            :disabled="!isObjectEmpty(errors) || !activeForm"
            class="btn btn-submit"
          >
            <span>【STEP3】<br />申込みフォームに進む</span>
          </button>
        </p>
      </Form>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { Form, Field, FieldArray, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import { mapActions, mapGetters } from "vuex";
import func from "@/func";
import { toRaw } from "vue";
import moment from "moment";

export default {
  name: "customize-plan-step-2",
  data: function () {
    return {
      ...func,
      enabledPlanIdx: Array.apply(null, {
        length: this.$store.state.planData.breadth?.max_cultivation_plan_count,
      }).map((_, idx) => idx !== 0),
      selectedVegetableIdx: 1,
      activeForm: false,
      rawVegetables: [],
      initialData: {
        planData: Array.apply(null, {
          length:
            this.$store.state.planData.breadth?.max_cultivation_plan_count,
        }).map(() => ({
          furrow: 0,
          vegetables: [
            {
              id: "",
            },
          ],
        })),
      },
      dateNow: moment().year(2000).format("YYYY-MM-01"),
      dateFormat: "YYYY-MM-01",
      startDate: "",
      endDate: "",
      furrowsOptions: [],
      schema: Yup.object().shape({
        planData: Yup.array().of(
          Yup.object().shape({
            furrow: Yup.number()
              .min(0)
              .nullable(true)
              .test("max", "error", (val) => {
                const furrowsOptions = toRaw(this.furrowsOptions);
                const { planData } = this.$refs.formPlan.getValues();
                const countTotal = planData.reduce(
                  (a, b) => a + (b.furrow || 0),
                  0
                );
                const maxLengthOption = furrowsOptions.length;
                const lastOption = furrowsOptions[maxLengthOption - 1];
                const isExceeding = countTotal === lastOption.value;

                if (val === 0 && countTotal < lastOption.value) {
                  return false;
                }
                return isExceeding;
              }),
            vegetables: Yup.array().of(
              Yup.object().shape({
                id: Yup.string()
                  .nullable()
                  .test("max", "error", (val) => {
                    const furrowsOptions = toRaw(this.furrowsOptions);
                    const maxLengthOption = furrowsOptions.length;
                    const lastOption = furrowsOptions[maxLengthOption - 1];
                    const { planData } = this.$refs.formPlan.getValues();
                    const countTotal = planData.reduce(
                      (a, b) => a + (b.furrow || 0),
                      0
                    );

                    const countFurrows = planData.filter(
                      (item) => item.furrow > 0
                    ).length;
                    const countVegetables = planData.filter(
                      (item) =>
                        item.vegetables.filter((item) => item.id).length > 0
                    ).length;

                    if (!val && countTotal === lastOption.value) {
                      return true && countFurrows === countVegetables;
                    }

                    if (!val) return false;

                    return true;
                  }),
              })
            ),
          })
        ),
      }),
    };
  },

  components: {
    Form,
    Field,
    FieldArray,
    ErrorMessage,
  },

  computed: {
    ...mapGetters({
      planData: "getPlan",
      vegetables: "getVegetables",
    }),
  },

  mounted() {
    if (!this.planData.breadth) {
      this.$router.push({ name: "customized_plan_step_1" });
      return;
    }

    this.getData();
    // this.$refs.btnSubmit.click();
    this.furrowsOptions = this.getOptionFurrows(this.planData.breadth.name);
    document.querySelector(".main__inner").scrollTo({ top: 0 });
  },

  methods: {
    ...mapActions(["setPlan", "setVegetables"]),

    getDateRange(vegetable) {
      const { start, end } = vegetable;
      const startDate = moment(start);
      const endDate = moment(end);

      const startMonth = startDate.format("M");
      const endMonth = endDate.format("M");

      return `(${startMonth}月〜${endMonth}月）`;
    },

    handleStatusVegetable(field, _field) {
      // this.$refs.btnSubmit.click();
      $("#btn-submit").click();
      return _field.value.id && !field.value.furrow;
    },

    handlePlanIdx(planData, idx) {
      const furrowsOptions = toRaw(this.furrowsOptions);
      const countTotal = planData.reduce((a, b) => a + (b.furrow || 0), 0);
      const maxLengthOption = furrowsOptions.length;
      const lastOption = furrowsOptions[maxLengthOption - 1];
      const firstIdx = planData.findIndex((i) => i.furrow === 0);
      const SelectedVegetables = planData.filter(
        (i) => i.vegetables.filter((_i) => _i.id).length
      ).length;

      if (lastOption.value > countTotal) {
        const SelectedFurrows = planData.filter((i) => i.furrow > 0).length;
        const t = planData[firstIdx];

        if (
          t.furrow === 0 &&
          SelectedVegetables === SelectedFurrows &&
          SelectedVegetables !== this.selectedVegetableIdx
        ) {
          this.enabledPlanIdx[firstIdx] = false;
          // this.selectedVegetableIdx = SelectedVegetables
          return;
        }
      }
      this.selectedVegetableIdx = SelectedVegetables;
      if (idx && firstIdx !== idx) {
        this.enabledPlanIdx[firstIdx] = true;
      }
    },

    handleRemoveData(remove, field, _field, idx) {
      if (idx > 0) {
        _field.value.id = "";
        field.value.vegetables.forEach((_, key) => {
          if (key > idx) {
            remove(key);
          }
        });
      } else {
        field.value.vegetables = [
          {
            id: "",
          },
        ];
      }

      setTimeout(() => {
        const { planData } = this.$refs.formPlan.getValues();
        this.setDatePeriod(planData);
      }, 100);
    },

    check2(item, vegetables) {
      const startDate = moment(item.start, this.dateFormat);
      const endDate = moment(item.end, this.dateFormat);

      const getLengthCheckDate = vegetables.filter((_item) => {
        const vegetable = this.vegetables.find(({ id }) => id === _item.id);
        const _startDate = moment(vegetable.start, this.dateFormat);
        const _endDate = moment(vegetable.end, this.dateFormat);

        if (endDate < _startDate || startDate > _endDate) {
          return true;
        }

        return false;
      }).length;

      if (getLengthCheckDate === vegetables.length) {
        return true;
      }

      return false;
    },

    getFurrowsOptions(planData, value) {
      const countTotal = planData.reduce((a, b) => a + (b.furrow || 0), 0);
      const maxLengthOption = this.furrowsOptions.length;
      const lastOption = this.furrowsOptions[maxLengthOption - 1];

      return this.furrowsOptions.filter((i) => {
        if (value.furrow) {
          return lastOption.value - countTotal + value.furrow >= i.value;
        } else {
          return lastOption.value - countTotal >= i.value;
        }
      });
    },

    getVegetableData(planData, vegetableSelected, vegetables, idx) {
      const startDate = this.startDate;
      const endDate = this.endDate;

      vegetables = toRaw(vegetables.filter((i) => i.id));
      vegetableSelected = toRaw(vegetableSelected);

      const vegetableData = [];
      planData.forEach((plan, idx) => {
        plan.vegetables.forEach((i) => {
          if (i.id && !vegetableData.find((_i) => _i.id === i.id)) {
            vegetableData.push({
              ...this.vegetables.find((_i) => _i.id === i.id),
              idx,
            });
          }
        });
      });

      const index = this.vegetables.findIndex(
        (item) => item.id === vegetableSelected.id
      );

      const results = this.vegetables.filter((item, key) => {
        if (index === key) return true;
        const check1 = !vegetables.find((_item) => _item.id === item.id);
        const check2 = this.check2(item, vegetables);
        const check3 = !vegetables.find((_item) => {
          const t = this.vegetables.find(({ id }) => id === _item.id);
          const c1 = this.checkYearDifference(startDate, t?.end);
          return (
            this.checkTime(item.start, t?.end) ||
            this.checkTime(item.end, t?.start) ||
            !c1
          );
        });

        var check5 = true;

        if (startDate) {
          const currentDateSelected = vegetableData.find(
            (i, _idx) =>
              i.idx !== idx && item.start === i.start && item.end === i.end
          );
          if (currentDateSelected) {
            check5 = true;
          } else {
            check5 =
              moment(startDate) <= moment(item.start) &&
              moment(endDate) >= moment(item.end);
          }
        }

        return check1 && check2 && check3 && check5;
      });

      return results;
    },

    checkTime(date1, date2) {
      if (!date1 || !date2) return true;

      const oneYearLater = moment(date1).add(1, "years");
      const oneYearEarlier = moment(date1).subtract(1, "years");

      if (moment(date2).isSameOrAfter(oneYearLater)) {
        return true;
      } else if (moment(date2).isSameOrBefore(oneYearEarlier)) {
        return true;
      }
      return false;
    },

    handleFurrowChange(fields, field, idx) {
      if (field.value.furrow === 0) {
        if (idx > 0) {
          fields.forEach((field, key) => {
            if (key > idx) {
              field.value = {
                furrow: 0,
                vegetables: [
                  {
                    id: "",
                  },
                ],
              };
              this.enabledPlanIdx[key] = true;
            } else if (key === idx) {
              setTimeout(() => {
                this.enabledPlanIdx[idx] = false;
              }, 10);
            }
          });
        } else {
          field.value = {
            furrow: 0,
            vegetables: [
              {
                id: "",
              },
            ],
          };
          this.startDate = "";
          this.endDate = "";

          fields.forEach((field, key) => {
            if (key > idx) {
              field.value = {
                furrow: 0,
                vegetables: [
                  {
                    id: "",
                  },
                ],
              };
              this.enabledPlanIdx[key] = true;
            }
          });
        }
      } else if (field.value.vegetables.filter((i) => i.id).length) {
        const countTotal = fields.reduce(
          (a, b) => a + (b.value.furrow || 0),
          0
        );
        const maxLengthOption = this.furrowsOptions.length;
        const lastOption = this.furrowsOptions[maxLengthOption - 1];

        if (lastOption.value > countTotal) {
          setTimeout(() => {
            const firstIdx = fields.findIndex((i) => i.value.furrow === 0);
            this.enabledPlanIdx[firstIdx] = false;
          }, 10);
        } else {
          setTimeout(() => {
            fields[idx + 1].value = {
              furrow: 0,
              vegetables: [
                {
                  id: "",
                },
              ],
            };
            this.enabledPlanIdx[idx + 1] = true;
          }, 10);
        }
      }
    },

    handleVegetableChange(
      idx,
      _idx,
      push,
      field,
      vegetableSelected,
      vegetables,
      remove,
      planData
    ) {
      if (_idx === 0 && !vegetableSelected.id) {
        field.value.vegetables = [
          {
            id: "",
          },
        ];
      } else if (_idx > 0 && !vegetableSelected.id) {
        field.value.vegetables.forEach((_, key) => {
          if (key > _idx) {
            remove(key);
          }
        });
      }

      this.setDatePeriod(planData);

      const checkLength = this.getVegetableData(
        planData,
        vegetableSelected,
        vegetables,
        idx
      );
      if (
        ((_idx === 0 &&
          field.value.vegetables.filter((i) => !i.id).length < 1) ||
          field.value.vegetables.filter((i) => !i.id).length === 0) &&
        checkLength.length > 1 &&
        vegetableSelected.id
      ) {
        push({ id: "" });
      }
    },

    getAllVegetableInPlanData(planData) {
      const vegetableData = [];
      planData.forEach((plan, idx) => {
        plan.vegetables.forEach((i) => {
          if (i.id && !vegetableData.find((_i) => _i.id === i.id)) {
            vegetableData.push({
              ...this.vegetables.find((_i) => _i.id === i.id),
            });
          }
        });
      });

      return vegetableData;
    },

    checkTypeHandlePeriod(vegetableData) {
      const minStartDate = moment.min(
        vegetableData.map((i) => moment(i.start))
      );
      const maxEndDate = moment.max(vegetableData.map((i) => moment(i.end)));

      var startDate = moment(maxEndDate, this.dateFormat)
        .subtract(1, "years")
        .format(this.dateFormat);
      var endDate = moment(minStartDate, this.dateFormat)
        .add(1, "years")
        .format(this.dateFormat);

      if (moment(endDate).diff(moment(startDate), "month") === 13) {
        startDate = moment(startDate)
          .subtract(1, "years")
          .format(this.dateFormat);
        endDate = moment(startDate)
          .subtract(1, "month")
          .format(this.dateFormat);
      }
    },

    resetDatePeriod() {
      this.startDate = "";
      this.endDate = "";
    },

    getDateFirstVegetableSelected(vegetableData) {
      const vegetable = vegetableData[0];

      return {
        startDateFirstVegetable: vegetable.start,
        endDateFirstVegetable: vegetable.end,
      };
    },

    calculateDatePeriod(vegetableData) {
      const { startDateFirstVegetable, endDateFirstVegetable } =
        this.getDateFirstVegetableSelected(vegetableData);

      const dateNow = moment(this.dateNow);
      let minStartDate = moment.min(vegetableData.map((i) => moment(i.start)));
      let maxEndDate = moment.max(vegetableData.map((i) => moment(i.end)));

      if (dateNow > minStartDate) {
        minStartDate = moment(minStartDate).add(1, "years");
        maxEndDate = moment(maxEndDate).add(1, "years");
      }

      const diffDatePeriod = moment(endDateFirstVegetable).diff(
        moment(startDateFirstVegetable),
        "month"
      );

      // case 1
      if (diffDatePeriod === 11 || diffDatePeriod === -1) {
        if (minStartDate > maxEndDate) {
          maxEndDate = moment(maxEndDate).add(1, "years");
        }

        this.startDate = minStartDate.format(this.dateFormat);
        this.endDate = maxEndDate.format(this.dateFormat);
      }
      // case 2
      else {
        minStartDate = moment.min(vegetableData.map((i) => moment(i.start)));
        maxEndDate = moment.max(vegetableData.map((i) => moment(i.end)));

        let startDate = moment(maxEndDate).subtract(1, "years").add(1, "month");
        let endDate = moment(minStartDate).add(1, "years").subtract(1, "month");

        if (startDate > endDate) {
          endDate = moment(endDate).add(1, "years");
        }

        // case 2.1
        if (vegetableData.length === 1) {
          this.startDate = moment(startDate).format(this.dateFormat);
          this.endDate = moment(endDate).format(this.dateFormat);
        }
        // case 2.2
        else {
          startDate = startDateFirstVegetable;
          endDate = endDateFirstVegetable;

          const checkDatePeriod = (vegetableData, startDate, endDate) => {
            for (const item of vegetableData) {
              const vegetablesFirstDatePeriod =
                this.isHasVegetableFirstDatePeriod(vegetableData, startDate);
              const vegetablesLastDatePeriod =
                this.isHasVegetableLastDatePeriod(vegetableData, endDate);

              if (vegetablesFirstDatePeriod) {
                startDate = moment
                  .min(vegetablesFirstDatePeriod.map((i) => moment(i.start)))
                  .format(this.dateFormat);
                const result = checkDatePeriod(
                  vegetableData,
                  startDate,
                  endDate
                );
                startDate = result.startDate;
                endDate = result.endDate;
                if (moment(startDate) > moment(endDate)) {
                  endDate = moment(endDate)
                    .add(1, "years")
                    .format(this.dateFormat);
                }
                break;
              } else if (vegetablesLastDatePeriod) {
                endDate = moment
                  .max(vegetablesLastDatePeriod.map((i) => moment(i.end)))
                  .format(this.dateFormat);
                const result = checkDatePeriod(
                  vegetableData,
                  startDate,
                  endDate
                );
                startDate = result.startDate;
                endDate = result.endDate;
                if (moment(startDate) > moment(endDate)) {
                  endDate = moment(endDate)
                    .add(1, "years")
                    .format(this.dateFormat);
                }
                break;
              }
            }

            return { startDate, endDate };
          };

          const result = checkDatePeriod(vegetableData, startDate, endDate);

          startDate = moment(result.endDate)
            .subtract(1, "years")
            .add(1, "month");
          endDate = moment(result.startDate)
            .add(1, "years")
            .subtract(1, "month");

          this.startDate = moment(startDate).format(this.dateFormat);
          this.endDate = moment(endDate).format(this.dateFormat);
        }
      }
    },

    isHasVegetableFirstDatePeriod(vegetableData, startDate) {
      const vegetables = this.getListVegetableAfter(vegetableData);

      const vegetable = vegetables.filter((item) => {
        return (
          (moment(item.end) >= moment(startDate) &&
            moment(item.start) < moment(startDate)) ||
          (moment(item.end).add(1, "years") >= moment(startDate) &&
            moment(item.start).add(1, "years") < moment(startDate)) ||
          (moment(item.end).subtract(1, "years") >= moment(startDate) &&
            moment(item.start).subtract(1, "years") < moment(startDate))
        );
      });

      if (vegetable.length > 0) return vegetable;
      else return false;
    },

    isHasVegetableLastDatePeriod(vegetableData, endDate) {
      const vegetables = this.getListVegetableAfter(vegetableData);

      const vegetable = vegetables.filter((item) => {
        return (
          (moment(item.start) <= moment(endDate) &&
            moment(item.end) > moment(endDate)) ||
          (moment(item.start).add(1, "years") <= moment(endDate) &&
            moment(item.end).add(1, "years") > moment(endDate)) ||
          (moment(item.start).subtract(1, "years") <= moment(endDate) &&
            moment(item.end).subtract(1, "years") > moment(endDate))
        );
      });

      if (vegetable.length > 0) return vegetable;
      else return false;
    },

    getListVegetableAfter(vegetables) {
      return [...vegetables]
        .filter((_, idx) => idx !== 0)
        .map((item) => {
          if (moment(item.start) > moment(item.end)) {
            item.end = moment(item.end).add(1, "years").format(this.dateFormat);
          }
          return item;
        });
    },

    setDatePeriod(planData) {
      console.log("--------------------StartLog-------------------");
      this.resetDatePeriod();
      const vegetableData = this.getAllVegetableInPlanData(planData);
      if (!vegetableData.length) return;
      this.calculateDatePeriod(vegetableData);
      const startDate = moment(this.startDate);
      const endDate = moment(this.endDate);

      const vegetableItems = [...this.rawVegetables].map((item) => {
        let vegetableStartDate = moment(item.start);
        let vegetableEndDate = moment(item.end);

        if (vegetableStartDate > vegetableEndDate) {
          vegetableEndDate = moment(vegetableEndDate).add(1, "years");
          item.end = vegetableEndDate.format(this.dateFormat);
        }

        if (
          moment(vegetableStartDate).add(1, "years") >= startDate &&
          moment(vegetableEndDate).add(1, "years") <= endDate
        ) {
          item.start = vegetableStartDate
            .add(1, "years")
            .format(this.dateFormat);
          item.end = vegetableEndDate.add(1, "years").format(this.dateFormat);
        }

        if (
          moment(vegetableStartDate).subtract(1, "years") >= startDate &&
          moment(vegetableEndDate).subtract(1, "years") <= endDate
        ) {
          item.start = vegetableStartDate
            .subtract(1, "years")
            .format(this.dateFormat);
          item.end = vegetableEndDate
            .subtract(1, "years")
            .format(this.dateFormat);
        }

        return item;
      });
      this.setVegetables(vegetableItems);

      // log data
      console.log(
        "vegetableItems",
        toRaw(
          vegetableItems.map((i) => {
            return {
              start: i.start,
              end: i.end,
              name: i.name,
            };
          })
        )
      );
      console.log("startDate", startDate.format(this.dateFormat));
      console.log("endDate", endDate.format(this.dateFormat));
      console.log("---------------------EndLog--------------------");
    },

    getVegetablesSelected(values) {
      const vegetables = [];
      values.planData.forEach((item) => {
        item.vegetables.forEach((_item) => {
          if (_item.id) {
            vegetables.push({ ...toRaw(_item), furrow: item.furrow });
          }
        });
      });
      return vegetables;
    },

    getVegetablesById(id) {
      return this.vegetables.find((item) => id === item.id).name;
    },

    calculate(item) {
      let x1 = 0;
      const vegetable = this.vegetables.find(({ id }) => id === item.id);
      const value = this.getValueFarmingPlan(this.planData.breadth.name);

      switch (this.planData.breadth.name) {
        case "5m":
          const x = vegetable.number * item.furrow;
          return x;

        case "おすすめプラン(5m)":
          const x0 = vegetable.number * item.furrow;
          return x0;

        case "1畝":
          const xt = vegetable.number * item.furrow;
          return xt;

        case "0.5反":
          const xn = value * item.furrow;
          x1 = xn * vegetable.number;
          return x1;

        case "1反":
          const xu = value * item.furrow;
          x1 = xu * vegetable.number;
          return x1;

        default:
          return 0;
      }
    },

    getData() {
      axios.post("/vegetables").then((response) => {
        const vegetableData = response.data;
        this.rawVegetables = [...vegetableData].map((item) => {
          item.start = moment(item.start).year(2000).format(this.dateFormat);
          item.end = moment(item.end).year(2000).format(this.dateFormat);
          return item;
        });

        this.setVegetables([...this.rawVegetables]);
      });
    },

    checkYearDifference(startDate, endDate) {
      if (!startDate) return true;
      const currentDate = moment(startDate);
      const differenceDate = moment(endDate, this.dateFormat);
      const yearDifference = currentDate.diff(differenceDate, "years");

      if (Math.abs(yearDifference) === 1) {
        return false;
      } else {
        return true;
      }
    },

    onSubmit(formData) {
      const vegetablePlanting = formData.planData;
      this.setPlan({ vegetablePlanting, action: "customized" });
      this.$router.push({ name: "form-info" });
    },

    convertKatakanaToHiragana(text) {
      const hiragana = text.replace(/[\u30A1-\u30F6]/g, function (match) {
        const offset = match.charCodeAt(0) - 0x30a1;
        return String.fromCharCode(0x3041 + offset);
      });

      return hiragana;
    },
  },
};
</script>

<style scoped>
.close-icon {
  display: none;
}

.select_wrap.selected::before {
  display: none;
}

.select_wrap.selected .close-icon {
  position: absolute;
  right: 11px;
  top: 8px;
  display: block !important;
}

.is-invalid {
  box-shadow: 0 0 5px var(--alertColor);
}

.disabled {
  cursor: not-allowed;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
  opacity: 0.6;
}

.disabled .select_wrap {
  pointer-events: none;
}
</style>
