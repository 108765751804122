import { createApp } from 'vue';

import store from '@/store';
import Router from '@/routes.js';
import Layout from '@/views/shared/layout.vue';
import VueAxios from 'vue-axios'
import axios from 'axios'
import '@/assets/css/styles.css';
import $ from 'jquery';
window.$ = $;

axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

const app = createApp(Layout);

app.use(Router);
app.use(VueAxios, axios);
app.use(store);
app.mount('#app');
