import { createWebHistory, createRouter } from 'vue-router'

import PageIndex from '@/views/pages/index.vue';
import CustomizedPlanStep1 from '@/views/pages/customizedPlan/step1.vue';
import CustomizedPlanStep2 from '@/views/pages/customizedPlan/step2.vue';
import RecommendPlanStep1 from '@/views/pages/recommendPlan/step1.vue';
import RecommendPlanStep2 from '@/views/pages/recommendPlan/step2.vue';
import FormInfo from '@/views/pages/form.vue';
import PlanPage from '@/views/pages/plan.vue';
import RawPage from '@/views/pages/raw.vue';
import TermsPage from '@/views/pages/terms.vue';
import PrivacyPage from '@/views/pages/privacy.vue';
import CompletePage from '@/views/pages/complete.vue';
import Error404 from '@/views/pages/errors/404.vue';
import Error422 from '@/views/pages/errors/422.vue';
import Error500 from '@/views/pages/errors/500.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/', component: PageIndex, name: 'home',
      meta: { title: '野菜と、未来と、わたしを耕すリモファム' }
    },
    {
      path: '/customized-plan/step-1', component: CustomizedPlanStep1, name: 'customized_plan_step_1',
      meta: { title: 'カスタマイズプラン［STEP1］ | 野菜と、未来と、わたしを耕すリモファム', class: '_plan' }
    },
    {
      path: '/customized-plan/step-2', component: CustomizedPlanStep2, name: 'customized_plan_step_2',
      meta: { title: 'カスタマイズプラン［STEP2］ | 野菜と、未来と、わたしを耕すリモファム', class: '_plan' }
    },
    {
      path: '/recommend-plan/step-1', component: RecommendPlanStep1, name: 'recommend_plan_step_1',
      meta: { title: '農家さんが選ぶおすすめプラン | 野菜と、未来と、わたしを耕すリモファム', class: '_plan' }
    },
    {
      path: '/recommend-plan/step-2', component: RecommendPlanStep2, name: 'recommend_plan_step_2',
      meta: { title: '自然栽培で美味しい野菜が食べられる！おすすめセット！ | 野菜と、未来と、わたしを耕すリモファム', class: '_plan' }
    },
    {
      path: '/form-info', component: FormInfo, name: 'form-info',
      meta: { title: '申し込みフォーム | 野菜と、未来と、わたしを耕すリモファム', class: '_plan' }
    },
    {
      path: '/complete', component: CompletePage, name: 'complete',
      meta: { title: '申し込み完了 | 野菜と、未来と、わたしを耕すリモファム', class: '_plan' }
    },
    {
      path: '/plan', component: PlanPage,
      meta: { title: 'プラン選択 | 野菜と、未来と、わたしを耕すリモファム', class: '_plan' }
    },
    {
      path: '/raw', component: RawPage,
      meta: { title: '特定商取引法 | 野菜と、未来と、わたしを耕すリモファム' }
    },
    {
      path: '/terms', component: TermsPage,
      meta: { title: '利用規約 | 野菜と、未来と、わたしを耕すリモファム' }
    },
    {
      path: '/privacy', component: PrivacyPage,
      meta: { title: 'プライバシーポリシー | 野菜と、未来と、わたしを耕すリモファム' }
    },
    {
      path: '/404', component: Error404,
      meta: { title: '404' }
    },
    {
      path: '/422', component: Error422,
      meta: { title: '422' }
    },
    {
      path: '/500', component: Error500,
      meta: { title: '500' }
    },
    {
      path: '/:catchAll(.*)', redirect: '/404',
    }
  ]
});

router.beforeEach((to, _) => {
  document.title = to.meta.title || ''
})

export default router;
