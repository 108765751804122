<template>
  <div class="container">
    <div class="plan_mv _small"><img src="/assets/img/mv_plan_detail.jpg" alt="" width="748" height="354" decoding="async"></div>
    <h1 class="ttl_basic02 u-ta_c">
      <!-- カスタマイズプランからの遷移の場合だけ表示 -->
      <span v-if="planData.action === 'customized'" class="_customized">
        カスタマイズプラン<br>【STEP3】
      </span>
      <!-- //ここまで -->
      申し込みフォーム
    </h1>

    <h2 class="ttl_basic03">注意事項</h2>
    <ul>
      <li v-if="planData.action === 'customized'">・収穫物は収穫後すぐに着払いにて発送します。送料は収獲量によって異なります。</li>
      <li>・収穫量を保証したものではございません。</li>
      <li>・収穫物は生鮮品であるため、送付した収穫物を理由に関わらずお受け取りいただけなかった場合、お客様のその収穫物（複数回にわたり収穫物を送付する場合は、その時に送付した収穫物）の権利は失われ、再送や代わりの作物への代替等はいたしかねますので予めご了承ください。</li>
    </ul>

    <Form v-if="planData.action" @submit="onSubmit" :validation-schema="schema" :initialErrors="{ agree: true }" v-slot="{ errors }">
      <!-- プラン内容の確認 -->
      <h2 class="u-visually_hidden">プラン内容</h2>
      <template v-if="planData.action === 'customized'">
        <dl class="confirm u-mt_lg">
          <div class="confirm__item">
            <dt class="ttl_sub01">地域</dt>
            <dd>{{ planData.region.name }}</dd>
          </div>
          <div class="confirm__item">
            <dt class="ttl_sub01">プラン</dt>
            <dd>カスタマイズプラン</dd>
          </div>
          <div class="confirm__item">
            <dt class="ttl_sub01">月額料金</dt>
            <dd>{{ formatMonthlyFee(planData.breadth.monthly_fee) }}円(税込)/月</dd>
          </div>
          <div class="confirm__item">
            <dt class="ttl_sub01">契約期間</dt>
            <dd>1年</dd>
          </div>
        </dl>
        <p class="u-c_gray u-mt_xs">※契約開始に関する注記とかあれば書いておく。</p>

        <!-- 栽培計画の確認 -->
        <h2 class="u-visually_hidden">栽培計画</h2>
        <div class="agri_confirm u-mt_md">
          <div class="agri_confirm__item">
            <h3 class="ttl_sub01">年間の予測収穫量</h3>
            <ul class="crop_list">
              <li v-for="(item, index) in planData.vegetablePlanting" :key="index">
                <template v-for="(_item, _key) in item.vegetables" :key="_key">
                  <template v-if="_item.id">
                    <span :class="`vegeicon ${convertKatakanaToHiragana(getVegetablesById(_item.id))}`"></span>
                    {{getVegetablesById(_item.id)}}: {{ calculate({ ..._item, furrow: item.furrow }) }}kg
                  </template>
                </template>
              </li>
            </ul>
          </div>
        </div>
      </template>

      <template v-else>
        <dl class="confirm u-mt_lg">
          <div class="confirm__item">
            <dt class="ttl_sub01">地域</dt>
            <dd>{{ planData.region.name }}</dd>
          </div>
          <div class="confirm__item">
            <dt class="ttl_sub01">プラン</dt>
            <dd>{{ planData.recommendPlanSelected.title }}</dd>
          </div>
          <div class="confirm__item">
            <dt class="ttl_sub01">月額料金</dt>
            <dd>{{ formatMonthlyFee(planData.breadth.monthly_fee) }}円(税込)/月</dd>
          </div>
          <div class="confirm__item">
            <dt class="ttl_sub01">契約期間</dt>
            <dd>1年</dd>
          </div>
        </dl>
        <p class="u-c_gray u-mt_xs">※契約開始に関する注記とかあれば書いておく。</p>

        <!-- 栽培計画の確認 -->
        <h2 class="u-visually_hidden">栽培計画</h2>
        <div class="agri_confirm u-mt_md">
          <div class="agri_confirm__item">
            <h3 class="ttl_sub01">年間の予測収穫量</h3>
            <ul class="crop_list">
              <li v-for="(vegetableItem, key) in planData.recommendPlanSelected.vegetables" :key="key">
                <span :class="`vegeicon ${convertKatakanaToHiragana(findVegetable(vegetableItem.id).name)}`"></span>
                {{ findVegetable(vegetableItem.id).name }}:{{calculate2(vegetableItem.id)}}kg
              </li>
            </ul>
          </div>
        </div>
      </template>

      <!-- 申込みフォーム -->
      <h2 class="u-visually_hidden">お申し込みフォーム</h2>
      <div class="form u-mt_md">
        <div class="form__item">
          <label for="name" class="ttl_sub01">氏名<span class="require">必須</span></label>
          <Field name="name" id="name" type="text" placeholder="農園太郎" />
          <div v-if="errors.name" class="error">正しく入力してください</div>
        </div>
        <div class="form__item">
          <label for="email" class="ttl_sub01">メールアドレス<span class="require">必須</span></label>
          <Field name="email" id="email" type="text" placeholder="sample@sample.com" />
          <div v-if="errors.email" class="error">正しく入力してください</div>
        </div>
        <div class="form__item">
          <label for="tel" class="ttl_sub01">電話番号（ハイフンなし）<span class="require">必須</span></label>
          <Field name="tel" id="tel" type="number" placeholder="0629805622" />
          <div v-if="errors.tel" class="error">正しく入力してください</div>
        </div>
        <div class="form__item">
          <label for="zip" class="ttl_sub01">郵便番号（ハイフンなし）<span class="require">必須</span></label>
          <Field name="zip" id="zip" type="number" placeholder="1111111" />
          <div v-if="errors.zip" class="error">正しく入力してください</div>
        </div>
        <div class="form__item">
          <label for="add1" class="ttl_sub01">住所<span class="require">必須</span></label>
          <Field name="add1" id="add1" type="text" placeholder="東京都練馬区" />
          <div v-if="errors.add1" class="error">正しく入力してください</div>
        </div>
        <div class="form__item">
          <label for="add2" class="ttl_sub01">番地・建物名<span class="require">必須</span></label>
          <Field name="add2" id="add2" type="text" placeholder="1-2-3　東京ハイツ101" />
          <div v-if="errors.add2" class="error">正しく入力してください</div>
        </div>
        <div class="form__item">
          <label for="msg" class="ttl_sub01">その他・ご相談</label>
          <Field name="msg" v-slot="{ field }">
            <textarea v-bind="field" name="msg" id="msg" cols="30" rows="7" placeholder="ご相談内容を入力してください"></textarea>
          </Field>
        </div>

        <div class="form__agree">
          <p>お客様の個人情報およびいただいた<br>電子メールの内容は、<br>
            <a href="/privacy" target="_blank">「個人情報のお取り扱いについて」</a>に従って<br>厳重な取り扱いを行います。
          </p>
          <div class="u-mt_sm">
            <Field name="agree" type="checkbox" id="agree" :value="true" />
            <label for="agree">「個人情報のお取り扱いについて」に同意する</label>
          </div>
        </div>

        <!-- 同意にチェックがついたら送信可能になる -->
        <div class="btn-center"><button ref="btnSubmit" class="btn" id="submit" :disabled="!isObjectEmpty(errors)">この内容で申し込む</button></div>

        <!-- エラーがある場合のみ出力 -->
        <div v-if="!isObjectEmpty(errors)" class="form__message">
          <p class="error">正しく入力できていない箇所があります</p>
        </div>

      </div>

    </Form>

    <transition name="modal">
      <confirm-modal v-if="showModal" @confirm="confirm()" @close="showModal = false" />
    </transition>
  </div>
</template>

<script>
import func from '@/func';
import { Form, Field } from 'vee-validate';
import * as Yup from 'yup';
import { mapActions, mapGetters } from 'vuex'
import ConfirmModal from '@/views/components/modal/confirm-modal.vue';
import { toRaw } from 'vue';
import axios from 'axios';
import moment from 'moment';

export default {
  name: 'form-info',
  data: function () {
    return {
      ...func,
      isOrdered: false,
      showModal: false,
      formData: {},
      furrowsOptions: [],
      schema: Yup.object().shape({
        name: Yup.string().max(256).required(),
        email: Yup.string()
                  .required()
                  .email()
                  .max(256)
                  .test(
                    'Validate Email',
                    'error',
                    (value) => {
                      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                      return re.test(String(value).toLowerCase())
                    },
                  ),
        tel: Yup.string().max(12).required(),
        zip: Yup.string().min(7).max(7).required(),
        add1: Yup.string().max(256).required(),
        add2: Yup.string().max(256).required(),
        msg: Yup.string().max(2000),
        agree: Yup.boolean().required(),
      }),
    }
  },

  components: {
    Form,
    Field,
    ConfirmModal
  },

  computed: {
    ...mapGetters({
      planData: 'getPlan',
      breadths: 'getBreadths',
      vegetables: 'getVegetables',
    })
  },

  mounted() {
    if(!this.planData.action) {
      this.$router.push({ name: 'home' })
      return;
    }
    // this.$refs.btnSubmit.click();
    document.querySelector('.main__inner').scrollTo({ top: 0 });
  },

  methods: {
    ...mapActions([
        'setPlan',
    ]),

    convertKatakanaToHiragana(text) {
      const hiragana = text.replace(/[\u30A1-\u30F6]/g, function(match) {
        const offset = match.charCodeAt(0) - 0x30A1;
        return String.fromCharCode(0x3041 + offset);
      });

      return hiragana;
    },

    getVegetablesById(id) {
      return this.vegetables.find(item => id === item.id).name
    },

    findBreadth(id) {
      return this.breadths.find(item => item.id === id)
    },

    findVegetable(id) {
      return this.vegetables.find(item => item.id === id)
    },


    calculate(item) {
      let x1 = 0;
      const vegetable = this.vegetables.find(({ id }) => id === item.id)
      const value = this.getValueFarmingPlan(this.planData.breadth.name)

      switch (this.planData.breadth.name) {
        case '5m':
          const x = vegetable.number * item.furrow
          return x;

        case 'おすすめプラン(5m)':
          const x0 = vegetable.number * item.furrow
          return x0;

        case '1畝':
          const xt = vegetable.number * item.furrow
          return xt;

        case '0.5反':
          const xn = value * item.furrow
          x1 = xn * vegetable.number
          return x1

        case '1反':
          const xu = value * item.furrow
          x1 = xu * vegetable.number
          return x1

        default:
          return 0;
      }
    },

    calculate2(vegetableId) {
      let totalVegetablesKg = 0;
      const { name } = this.findBreadth(this.planData.recommendPlanSelected.breadths[0].id)
      const vegetable = this.vegetables.find(({ id }) => id === vegetableId)
      const value = this.getValueFarmingPlan(name)
      const furrow = 1;

      switch (name) {
        case '5m':
          const x = vegetable.number * furrow
          return x;

        case 'おすすめプラン(5m)':
          const x0 = vegetable.number * furrow
          return x0;

        case '1畝':
          const xt = vegetable.number * furrow
          return xt;

        case '0.5反':
          const xn = value * furrow
          totalVegetablesKg = xn * vegetable.number
          return totalVegetablesKg

        case '1反':
          const xu = value * furrow
          totalVegetablesKg = xu * vegetable.number
          return totalVegetablesKg

        default:
          return 0;
      }
    },

    formatMonthlyFee(monthlyFee) {
      return new Intl.NumberFormat().format(monthlyFee);
    },

    confirm() {
      this.showModal = false;
      this.setPlan({ info: this.formData })

      let formData;
      if(this.planData.action === 'customized') {
         formData = this.getCustomizedData()
      } else {
         formData = this.getRecommendData()
      }

      if(!this.isOrdered) {
        axios.post('/order', formData);
        this.isOrdered = true;
      }

      this.$router.push({ name: 'complete' })
    },

    getSize(furrow) {
      switch (this.planData.breadth.name) {
        case '5m':
          return furrow * 5;
        case 'おすすめプラン(5m)':
          return furrow * 5;
        case '1畝':
          return furrow * 5;
        case '0.5反':
          return furrow * 30;
        case '1反':
          return furrow * 30;
      }
    },

    getCustomizedData() {
      return {
        ...this.formData,
        tel: this.planData.info.tel.toString(),
        zip: this.planData.info.zip.toString(),
        region: this.planData.region,
        from_action: this.planData.action,
        breadth: toRaw(this.planData.breadth),
        add1: this.formData.add1 + ' ' + this.formData.add2,
        settlement_start_date: moment().format('YYYY-MM-DD'),
        vegetables_plans: this.planData.vegetablePlanting.map(item => {
          return {
            ids: item.vegetables.filter(_item => _item.id).map(_item => ({
              id: _item.id,
            })),
            size: this.getSize(item.furrow),
          }
        }).filter(item => item.ids.length)
      }
    },

    getRecommendData() {
      return {
        ...this.formData,
        tel: this.planData.info.tel.toString(),
        zip: this.planData.info.zip.toString(),
        region: this.planData.region,
        from_action: this.planData.action,
        breadth: toRaw(this.planData.breadth),
        settlement_start_date: moment().format('YYYY-MM-DD'),
        recommend_plan_selected: toRaw(this.planData.recommendPlanSelected),
      }
    },

    onSubmit(formData) {
      this.formData = formData;
      this.showModal = true;
    }
  }
};
</script>

<style scoped>
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
