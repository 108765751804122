import { createStore } from 'vuex'
import { toRaw } from 'vue';

export default createStore({
  state: {
    breadths: [],
    vegetables: [],
    regions: [],
    recommendPlans: [],
    planData: {},
  },

  mutations: {
    SET_PLAN(state, payload) {
      state.planData = { ...state.planData, ...payload }
    },
    SET_RECOMMEND_PLANS(state, payload) {
      state.recommendPlans = payload
    },
    SET_BREADTHS(state, payload) {
      state.breadths = payload
    },
    SET_VEGETABLES(state, payload) {
      state.vegetables = payload
    },
    SET_REGIONS(state, payload) {
      state.regions = payload
    },
  },

  actions: {
    setPlan(context, payload) {
      context.commit('SET_PLAN', payload)
    },
    setRecommendPlans(context, payload) {
      context.commit('SET_RECOMMEND_PLANS', payload)
    },
    setBreadths(context, payload) {
      context.commit('SET_BREADTHS', payload)
    },
    setVegetables(context, payload) {
      context.commit('SET_VEGETABLES', payload)
    },
    setRegions(context, payload) {
      context.commit('SET_REGIONS', payload)
    },
  },

  getters: {
    getPlan (state) {
      return toRaw(state.planData);
    },
    getRecommendPlans (state) {
      return toRaw(state.recommendPlans);
    },
    getBreadths (state) {
      return toRaw(state.breadths);
    },
    getVegetables (state) {
      return toRaw(state.vegetables);
    },
    getRegions (state) {
      return toRaw(state.regions);
    },
  }
})
