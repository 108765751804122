<template>
  <div class="container">
    <div class="plan_mv _small">
      <img
        src="/assets/img/mv_plan_detail.jpg"
        alt=""
        width="748"
        height="354"
        decoding="async"
      />
    </div>
    <h1 class="ttl_basic02 u-ta_c">カスタマイズプラン<br />【STEP1】</h1>

    <h2 class="ttl_basic03">注意事項</h2>
    <ul>
      <li>
        ・収穫物は収穫後すぐに着払いにて発送します。送料は収獲量によって異なります。
      </li>
      <li>・収穫量を保証したものではございません。</li>
      <li>
        ・収穫物は生鮮品であるため、送付した収穫物を理由に関わらずお受け取りいただけなかった場合、お客様のその収穫物（複数回にわたり収穫物を送付する場合は、その時に送付した収穫物）の権利は失われ、再送や代わりの作物への代替等はいたしかねますので予めご了承ください。
      </li>
    </ul>

    <!-- 地域と土地の広さを決める -->
    <section>
      <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
        <h2 class="ttl_basic02">地域と土地の広さを決める</h2>

        <div>
          <label for="regionId" class="ttl_sub01">地域</label>
          <div class="select_wrap">
            <Field name="regionId" id="regionId" as="select">
              <option value="">選択してください</option>
              <option v-for="region in regions" :value="region.id">
                {{ region.name }}
              </option>
            </Field>
          </div>
        </div>
        <div class="u-mt_xs">
          <label for="breadthId" class="ttl_sub01">広さ</label>
          <div class="select_wrap">
            <Field name="breadthId" id="breadthId" as="select">
              <option value="">選択してください</option>
              <option
                v-for="breadth in breadths.filter((i) => i.is_customized_plan)"
                :value="breadth.id"
              >
                {{
                  breadth.name + " " + formatMonthlyFee(breadth.monthly_fee)
                }}(税込)/月
              </option>
            </Field>
          </div>
        </div>

        <!-- 畑の面積・単位について -->
        <area-and-size />

        <input value="2" name="step" hidden />
        <!-- 申込みボタン -->
        <p class="btn-center">
          <button
            ref="btnSubmit"
            :disabled="!isObjectEmpty(errors)"
            class="btn"
            type="submit"
          >
            <span>【STEP2】<br />栽培する野菜を選ぶ</span>
          </button>
        </p>
      </Form>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { mapActions, mapGetters } from "vuex";
import func from "@/func";
import areaAndSize from "@/views/components/area-and-size.vue";

export default {
  name: "customize-plan-step-1",
  data: function () {
    return {
      ...func,
      schema: Yup.object().shape({
        breadthId: Yup.string().required(),
        regionId: Yup.string().required(),
      }),
    };
  },

  components: {
    Form,
    Field,
    areaAndSize,
  },

  mounted: function () {
    this.getData();
    this.$refs.btnSubmit.click();
  },

  computed: {
    ...mapGetters({
      breadths: "getBreadths",
      regions: "getRegions",
    }),
  },

  methods: {
    ...mapActions(["setPlan", "setBreadths", "setRegions"]),

    getData: function () {
      Promise.all([axios.post("/breadths"), axios.post("/regions")]).then(
        ([breadthResponse, regionResponse]) => {
          this.setBreadths(
            breadthResponse.data.sort((a, b) => a.monthly_fee - b.monthly_fee)
          );
          this.setRegions(regionResponse.data);
        }
      );
    },

    formatMonthlyFee(monthlyFee) {
      return new Intl.NumberFormat().format(monthlyFee);
    },

    onSubmit(formData) {
      const breadth = this.breadths.find(({ id }) => id === formData.breadthId);
      const region = this.regions.find(({ id }) => id === formData.regionId);
      this.setPlan({ breadth, region });
      this.$router.push({ name: "customized_plan_step_2" });
    },
  },
};
</script>
