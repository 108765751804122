<template>
  <div v-if="planData.recommendPlanSelected" class="container">
    <div class="plan_mv _small">
      <img
        src="/assets/img/mv_plan_detail.jpg"
        alt=""
        width="748"
        height="354"
        decoding="async"
      />
    </div>
    <h1 class="ttl_basic02 u-ta_c">
      {{ planData.recommendPlanSelected.title }}
    </h1>
    <ul>
      <li>・収穫量を保証したものではございません。</li>
      <li>
        ・収穫物は生鮮品であるため、送付した収穫物を理由に関わらずお受け取りいただけなかった場合、お客様のその収穫物（複数回にわたり収穫物を送付する場合は、その時に送付した収穫物）の権利は失われ、再送や代わりの作物への代替等はいたしかねますので予めご了承ください。
      </li>
    </ul>

    <!-- プラン詳細データ -->
    <div class="details u-mt_md">
      <div class="details__inner">
        <dl class="details__data">
          <dt>地域</dt>
          <dd>
            {{ findRegion(planData.recommendPlanSelected.regions[0].id).name }}
          </dd>
          <dt>栽培計画</dt>
          <dd>
            <ul>
              <li
                v-for="(vegetableItem, key) in planData.recommendPlanSelected
                  .vegetables"
                :key="key"
              >
                <span
                  :class="`vegeicon ${convertKatakanaToHiragana(
                    findVegetable(vegetableItem.id).name
                  )}`"
                ></span>
                {{ findVegetable(vegetableItem.id).name }}
                {{ getDateRangeById(vegetableItem.id) }}
              </li>
            </ul>
          </dd>
          <dt>年間の予測収穫量</dt>
          <dd>
            <ul class="crop_list">
              <li
                v-for="(vegetableItem, key) in planData.recommendPlanSelected
                  .vegetables"
                :key="key"
              >
                <span
                  :class="`vegeicon ${convertKatakanaToHiragana(
                    findVegetable(vegetableItem.id).name
                  )}`"
                ></span>
                {{ findVegetable(vegetableItem.id).name }}:{{
                  calculate(vegetableItem.id)
                }}kg
              </li>
            </ul>
          </dd>
          <dt>費用</dt>
          <dd>
            <ul class="databox">
              <li class="databox__item">
                <span class="databox__unit">{{
                  findBreadth(planData.recommendPlanSelected.breadths[0].id)
                    .name
                }}</span>
                <span class="databox__data"
                  ><span class="num">{{
                    formatMonthlyFee(
                      findBreadth(planData.recommendPlanSelected.breadths[0].id)
                        .monthly_fee
                    )
                  }}</span
                  >円(税込)/月</span
                >
              </li>
            </ul>
            <p class="u-fz_s">※収穫した野菜の送料込み</p>
          </dd>
        </dl>
      </div>
    </div>

    <!-- 畑の面積・単位について -->
    <area-and-size />

    <!-- 申込みボタン -->
    <p class="btn-center">
      <a href="#" @click="onSubmit()" class="btn">申込みフォームに進む</a>
    </p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import func from "@/func";
import areaAndSize from "@/views/components/area-and-size.vue";

export default {
  name: "recommend-plan-step-2",
  data: function () {
    return {
      ...func,
    };
  },

  components: {
    areaAndSize,
  },

  computed: {
    ...mapGetters({
      planData: "getPlan",
      regions: "getRegions",
      breadths: "getBreadths",
      vegetables: "getVegetables",
      recommendPlans: "getRecommendPlans",
    }),
  },

  mounted() {
    if (!this.planData.recommendPlanSelected) {
      this.$router.push({ name: "recommend_plan_step_1" });
      return;
    }
    document.querySelector(".main__inner").scrollTo({ top: 0 });
  },

  methods: {
    ...mapActions(["setPlan"]),

    findRegion(id) {
      return this.regions.find((item) => item.id === id);
    },

    findBreadth(id) {
      return this.breadths.find((item) => item.id === id);
    },

    findVegetable(id) {
      return this.vegetables.find((item) => item.id === id);
    },

    formatMonthlyFee(monthlyFee) {
      return new Intl.NumberFormat().format(monthlyFee);
    },

    onSubmit() {
      this.setPlan({ action: "recommend" });
      this.$router.push({ name: "form-info" });
    },

    calculate(vegetableId) {
      let x1 = 0;
      const { name } = this.findBreadth(
        this.planData.recommendPlanSelected.breadths[0].id
      );
      const vegetable = this.vegetables.find(({ id }) => id === vegetableId);
      const value = this.getValueFarmingPlan(name);
      const furrow = 1;

      switch (name) {
        case "5m":
          const x = vegetable.number * furrow;
          return x;

        case "おすすめプラン(5m)":
          const x0 = (vegetable.number * furrow) / 2;
          return x0;

        case "1畝":
          const xt = vegetable.number * furrow;
          return xt;

        case "0.5反":
          const xn = value * furrow;
          x1 = xn * vegetable.number;
          return x1;

        case "1反":
          const xu = value * furrow;
          x1 = xu * vegetable.number;
          return x1;

        default:
          return 0;
      }
    },

    getDateRangeById(id) {
      const { start, end } = this.findVegetable(id);
      const startDate = moment(start);
      const endDate = moment(end);

      const startMonth = startDate.format("M");
      const endMonth = endDate.format("M");

      return `(${startMonth}月〜${endMonth}月）`;
    },

    convertKatakanaToHiragana(text) {
      const hiragana = text.replace(/[\u30A1-\u30F6]/g, function (match) {
        const offset = match.charCodeAt(0) - 0x30a1;
        return String.fromCharCode(0x3041 + offset);
      });

      return hiragana;
    },
  },
};
</script>
