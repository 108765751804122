<template>
  <section class="container _bottom_gap_half">

    <!-- プラン概要 -->
    <div class="details">
      <div class="details__thumb"><img src="/assets/img/mv_plan_detail.jpg" alt="" width="748" height="354" decoding="async" loading="lazy"></div>
      <div v-for="(plan, key) in recommendPlans" :key="key" :class="{ 'mt-5': key > 0 }" class="details__inner">
        <h2 class="ttl_basic02 u-ta_c">{{ plan.title }}</h2>
        <dl class="details__data">
          <dt>地域</dt>
          <dd>{{ findRegion(plan.regions[0].id).name }}</dd>
          <dt>広さ</dt>
          <dd>
            <ul class="databox">
              <li class="databox__item">
                <span class="databox__unit">{{ findBreadth(plan.breadths[0].id).name }}</span>
                <span class="databox__data"><span class="num">{{ formatMonthlyFee(findBreadth(plan.breadths[0].id).monthly_fee) }}</span>円(税込)/月</span>
              </li>
            </ul>
            <p class="u-fz_s">※収穫した野菜の送料込み</p>
          </dd>
          <dt>栽培計画</dt>
          <dd>
            <ul>
              <li v-for="(vegetableItem, key) in plan.vegetables" :key="key">
                <span :class="`vegeicon ${convertKatakanaToHiragana(findVegetable(vegetableItem.id).name)}`"></span>
                {{ findVegetable(vegetableItem.id).name }}
                {{ getDateRangeById(vegetableItem.id) }}
              </li>
            </ul>
          </dd>
        </dl>
        <p class="details__btn" @click="onSubmit(plan)"><a href="#" class="btn">詳細を見る</a></p>
      </div>
    </div>

    <!-- 畑の面積・単位について -->
    <area-and-size />
  </section>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment';
import areaAndSize from '@/views/components/area-and-size.vue';

export default {
  name: 'recommend-plan-step-1',
  mounted () {
    this.getData();
  },

  components: {
    areaAndSize
  },

  computed: {
    ...mapGetters({
      regions: 'getRegions',
      breadths: 'getBreadths',
      vegetables: 'getVegetables',
      recommendPlans: 'getRecommendPlans',
    })
  },

  methods: {
    ...mapActions([
        'setPlan',
        'setRegions',
        'setBreadths',
        'setVegetables',
        'setRecommendPlans',
    ]),

    findRegion(id) {
      return this.regions.find(item => item.id === id)
    },

    findBreadth(id) {
      return this.breadths.find(item => item.id === id)
    },

    findVegetable(id) {
      return this.vegetables.find(item => item.id === id)
    },

    formatMonthlyFee(monthlyFee) {
      return new Intl.NumberFormat().format(monthlyFee);
    },

    getData() {
      Promise.all([
        axios.post('/regions'),
        axios.post('/breadths'),
        axios.post('/vegetables'),
        axios.post('/recommend_plans'),
      ]).then(([regionResponse, breadthResponse, vegetableResponse, recommendPlanResponse]) => {
        this.setRegions(regionResponse.data)
        this.setBreadths(breadthResponse.data)
        this.setVegetables(vegetableResponse.data)
        this.setRecommendPlans(recommendPlanResponse.data)
      });
    },

    onSubmit(recommendPlanSelected) {
      const region = this.findRegion(recommendPlanSelected.regions[0].id)
      const breadth = this.findBreadth(recommendPlanSelected.breadths[0].id)
      this.setPlan({ recommendPlanSelected, region, breadth });
      this.$router.push({ name: 'recommend_plan_step_2' })
    },

    getDateRangeById(id) {
      const { start, end } = this.findVegetable(id);
      const startDate = moment(start);
      const endDate = moment(end);

      const startMonth = startDate.format('M');
      const endMonth = endDate.format('M');

      return `(${startMonth}月〜${endMonth}月）`;
    },

    convertKatakanaToHiragana(text) {
      const hiragana = text.replace(/[\u30A1-\u30F6]/g, function(match) {
        const offset = match.charCodeAt(0) - 0x30A1;
        return String.fromCharCode(0x3041 + offset);
      });

      return hiragana;
    }
  }
};
</script>

<style scoped>
.mt-5 {
  margin-top: 5px;
}
</style>
