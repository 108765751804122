<template>
  <div class="wrapper" :class="$router.currentRoute.value.meta.class">
    <div class="bg"></div>
    <main class="main">
      <div class="main__inner">
        <div class="header">
          <p class="header__logo"><a href="/"><img src="/assets/img/logo.svg" alt="REMOFAM" width="155" height="19" decoding="async"></a></p>
        </div>
        <router-view />
      </div>
    </main>
    <nav-bar />
    <Footer />
  </div>
</template>

<script>
import NavBar from './_navbar.vue';
import Footer from './_footer.vue';
import commonScript from '@/assets/js/common.js';

export default {
  components: {
    'nav-bar': NavBar,
    'Footer': Footer,
  },
  mounted() {
    setTimeout(() => {
      commonScript.init()
    }, 250);
  },
}
</script>

