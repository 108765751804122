import moment from 'moment';

function getOptionFurrows(name) {
  switch (name) {
    case '5m':
      return [
        {
          label: '5m',
          value: 1
        }
      ];

    case 'おすすめプラン(5m)':
      return [
        {
          label: '5m',
          value: 1
        }
      ];

    case '1畝':
      return [
        {
          label: '5m',
          value: 1
        },
        {
          label: '10m',
          value: 2
        },
        {
          label: '15m',
          value: 3
        },
        {
          label: '20m',
          value: 4
        },
        {
          label: '25m',
          value: 5
        },
        {
          label: '30m',
          value: 6
        },
      ];

    case '0.5反':
      return [
        {
          label: '1畝',
          value: 1
        },
        {
          label: '2畝',
          value: 2
        },
        {
          label: '3畝',
          value: 3
        },
        {
          label: '4畝',
          value: 4
        },
        {
          label: '5畝',
          value: 5
        },
        {
          label: '6畝',
          value: 6
        },
        {
          label: '7畝',
          value: 7
        },
        {
          label: '8畝',
          value: 8
        },
        {
          label: '9畝',
          value: 9
        },
        {
          label: '10畝',
          value: 10
        },
      ];

    case '1反':
      return [
        {
          label: '1畝',
          value: 1
        },
        {
          label: '2畝',
          value: 2
        },
        {
          label: '3畝',
          value: 3
        },
        {
          label: '4畝',
          value: 4
        },
        {
          label: '5畝',
          value: 5
        },
        {
          label: '6畝',
          value: 6
        },
        {
          label: '7畝',
          value: 7
        },
        {
          label: '8畝',
          value: 8
        },
        {
          label: '9畝',
          value: 9
        },
        {
          label: '10畝',
          value: 10
        },
        {
          label: '11畝',
          value: 11
        },
        {
          label: '12畝',
          value: 12
        },
        {
          label: '13畝',
          value: 13
        },
        {
          label: '14畝',
          value: 14
        },
        {
          label: '15畝',
          value: 15
        },
        {
          label: '16畝',
          value: 16
        },
        {
          label: '17畝',
          value: 17
        },
        {
          label: '18畝',
          value: 18
        },
        {
          label: '19畝',
          value: 19
        },
        {
          label: '20畝',
          value: 20
        },
      ];

    default:
      return []
  }
}

function checkTimeValidity(vegetableData) {
  for (let i = 0; i < vegetableData.length - 1; i++) {
    const currentData = vegetableData[i];
    const nextData = vegetableData[i + 1];

    const currentStart = moment(currentData.start);
    const currentEnd = moment(currentData.end);
    const nextStart = moment(nextData.start);
    const nextEnd = moment(nextData.end);

    if (currentEnd.isSameOrAfter(nextStart)) {
      return false;
    }
  }

  return true;
}

function getValueFarmingPlan(name) {
  switch (name) {
    case '5m':
      return 1

    case 'おすすめプラン(5m)':
      return 1

    case '1畝':
      return 1

    case '0.5反':
      return 6

    case '1反':
      return 6

    default:
      return 0
  }
}

function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export default {
  getOptionFurrows,
  checkTimeValidity,
  getValueFarmingPlan,
  isObjectEmpty,
}
