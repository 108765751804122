<template>
  <div class="container">
    <h1 class="ttl_basic02 u-ta_c">
      申し込み完了
    </h1>

    <div>
      <p class="u-fz_l">お申込みが完了いたしました。<br>
        下記stripeにて契約料を決済いただくことで契約農園の権利が確定いたします。<br>
        10日以内にご決済が確認できない場合は自動的にキャンセルになってしまいますのでご注意ください。<br>
        「info@minnademiraio.net」からのメールが受け取れる設定にするようにお願いいたします。
      </p>
    </div>

    <p v-if="this.planData.info" class="btn-center">
      <a :href="this.planData.breadth.stripe_url" target="_blank" rel="noreferrer" class="btn">stripeで決済する</a>
    </p>
    <p class="u-ta_c u-mt_xs">※外部サイトに遷移します。</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'complete',

  computed: {
    ...mapGetters({
      planData: 'getPlan',
    })
  },

  mounted() {
    if(!this.planData.info) {
      this.$router.push({ name: 'home' })
      return;
    }
    document.querySelector('.main__inner').scrollTo({ top: 0 });
  },
};
</script>
